<template>
  <v-form ref="form">
    <v-row>
      <v-col>
        <v-text-field
          label="Valor Salário"
          v-model="formCadFin.valor_salario"
          :rules="[rules.required, rules.numeric, rules.max8]"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <BaseSelect
          label="Calcula Comissão"
          :items="[
            { text: 'N', value: 'N' },
            { text: 'P', value: 'P' }
          ]"
        />
      </v-col>
      <v-col>
        <BaseSelect
          label="Grupo Comissão"
          v-model="formCadFin.id_grupo_comissao"
          :items="itemsGC"
          :multiple="false"
          @itemSelect="formCadFin.id_grupo_comissao = $event"
          :rules="[rules.notNULL, rules.numeric]"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <BaseSelect
          label="Processa Comissão"
          :items="[
            { text: 'Sim', value: 'S' },
            { text: 'Não', value: 'N' }
          ]"
          :rules="[rules.notNULL]"
          @itemSelect="formCadFin.processa = $event"
        />
      </v-col>
      <v-col>
        <BaseSelect
          label="Calcula Comissão"
          :items="[
            { text: 'Sim', value: 'S' },
            { text: 'Não', value: 'N' }
          ]"
          :rules="[rules.notNULL]"
          @itemSelect="formCadFin.calcula_comissao = $event"
        />
      </v-col>
      <v-col>
        <BaseSelect
          label="Integra flyVendas"
          :items="[
            { text: 'Sim', value: 'S' },
            { text: 'Não', value: 'N' }
          ]"
          :rules="[rules.notNULL]"
          @itemSelect="formCadFin.integra_flyvendas = $event"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <BaseSelect
          label="Processa RPA Peças"
          :items="[
            { text: 'Sim', value: 'S' },
            { text: 'Não', value: 'N' }
          ]"
          :rules="[rules.notNULL]"
          @itemSelect="formCadFin.processa_rpa_pecas = $event"
        />
      </v-col>
      <v-col>
        <BaseSelect
          label="Ativa Campanha"
          :items="[
            { text: 'Sim', value: 'S' },
            { text: 'Não', value: 'N' }
          ]"
          :rules="[rules.notNULL]"
          @itemSelect="formCadFin.ativa_campanha = $event"
        />
      </v-col>
      <v-col>
        <BaseSelect
          label="Ativar Empresa"
          :items="[
            { text: 'Sim', value: 'S' },
            { text: 'Não', value: 'N' }
          ]"
          :rules="[rules.notNULL]"
          @itemSelect="formCadFin.ativo = $event"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import rules from "@/mixins/inputRules";
import serviceGrupoComissao from "@/services/comissao";
import { mapActions } from "vuex";

export default {
  name: "FormCadastroDadosFinanceiros",

  mixins: [rules],

  components: {
    BaseSelect: () => import("@/components/shared/BaseSelect")
  },

  data() {
    return {
      itemsGC: [],
      formCadFin: {
        valor_salario: "",
        tipo_com_cons: "",
        id_grupo_comissao: "",
        processa: "",
        calcula_comissao: "",
        integra_flyvendas: "",
        processa_rpa_pecas: "",
        ativo: "",
        ativa_campanha: ""
      },
      rules: {
        notNULL: notNULL => {
          return (
            (notNULL && notNULL.length != 0) || "O campo não pode ser vazio"
          );
        },

        max8: max8 => {
          return (
            (max8 && max8.length <= 8) ||
            "O campo deve conter no máximo 8 dígitos"
          );
        }
      }
    };
  },

  watch: {
    formCadFin: {
      handler() {
        this.fillSetFormEmpresa(this.formCadFin);
      },
      deep: true
    }
  },

  methods: {
    ...mapActions("empresa", ["fillSetFormEmpresa"]),

    async selectGrupoComissao() {
      try {
        const response = await serviceGrupoComissao.getGrupoComissao();
        response.data.data.forEach(grupoComissao => {
          const item = {
            text: grupoComissao.descricao,
            value: grupoComissao.id_grupo_comissao
          };
          this.itemsGC.push(item);
        });
      } catch (error) {
        console.error(error);
      }
    },

    validateForm() {
      return this.$refs.form.validate();
    }
  },
  mounted() {
    this.selectGrupoComissao();
  }
};
</script>

<style></style>
