var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Valor Salário","rules":[_vm.rules.required, _vm.rules.numeric, _vm.rules.max8]},model:{value:(_vm.formCadFin.valor_salario),callback:function ($$v) {_vm.$set(_vm.formCadFin, "valor_salario", $$v)},expression:"formCadFin.valor_salario"}})],1),_c('v-col',[_c('BaseSelect',{attrs:{"label":"Calcula Comissão","items":[
          { text: 'N', value: 'N' },
          { text: 'P', value: 'P' }
        ]}})],1),_c('v-col',[_c('BaseSelect',{attrs:{"label":"Grupo Comissão","items":_vm.itemsGC,"multiple":false,"rules":[_vm.rules.notNULL, _vm.rules.numeric]},on:{"itemSelect":function($event){_vm.formCadFin.id_grupo_comissao = $event}},model:{value:(_vm.formCadFin.id_grupo_comissao),callback:function ($$v) {_vm.$set(_vm.formCadFin, "id_grupo_comissao", $$v)},expression:"formCadFin.id_grupo_comissao"}})],1)],1),_c('v-row',[_c('v-col',[_c('BaseSelect',{attrs:{"label":"Processa Comissão","items":[
          { text: 'Sim', value: 'S' },
          { text: 'Não', value: 'N' }
        ],"rules":[_vm.rules.notNULL]},on:{"itemSelect":function($event){_vm.formCadFin.processa = $event}}})],1),_c('v-col',[_c('BaseSelect',{attrs:{"label":"Calcula Comissão","items":[
          { text: 'Sim', value: 'S' },
          { text: 'Não', value: 'N' }
        ],"rules":[_vm.rules.notNULL]},on:{"itemSelect":function($event){_vm.formCadFin.calcula_comissao = $event}}})],1),_c('v-col',[_c('BaseSelect',{attrs:{"label":"Integra flyVendas","items":[
          { text: 'Sim', value: 'S' },
          { text: 'Não', value: 'N' }
        ],"rules":[_vm.rules.notNULL]},on:{"itemSelect":function($event){_vm.formCadFin.integra_flyvendas = $event}}})],1)],1),_c('v-row',[_c('v-col',[_c('BaseSelect',{attrs:{"label":"Processa RPA Peças","items":[
          { text: 'Sim', value: 'S' },
          { text: 'Não', value: 'N' }
        ],"rules":[_vm.rules.notNULL]},on:{"itemSelect":function($event){_vm.formCadFin.processa_rpa_pecas = $event}}})],1),_c('v-col',[_c('BaseSelect',{attrs:{"label":"Ativa Campanha","items":[
          { text: 'Sim', value: 'S' },
          { text: 'Não', value: 'N' }
        ],"rules":[_vm.rules.notNULL]},on:{"itemSelect":function($event){_vm.formCadFin.ativa_campanha = $event}}})],1),_c('v-col',[_c('BaseSelect',{attrs:{"label":"Ativar Empresa","items":[
          { text: 'Sim', value: 'S' },
          { text: 'Não', value: 'N' }
        ],"rules":[_vm.rules.notNULL]},on:{"itemSelect":function($event){_vm.formCadFin.ativo = $event}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }